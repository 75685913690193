@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}
@layer base {
  body {
    @apply bg-gray-100;
    @apply text-slate-600;
    @apply font-poppins
  }
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
/* ::-webkit-scrollbar-track {
  background: #e2e8f0;
} */
::-webkit-scrollbar-thumb {
  background: #a6a8ab;
  border-radius: 8px;
}
@layer components {
  ::file-selector-button {
    @apply text-gray-800 bg-gray-300 hover:bg-gray-200
      font-medium text-sm cursor-pointer
      border-0 border-r border-solid border-gray-300
      py-2 px-3;
    margin-inline-end: 0.5rem;
  }
  ::-webkit-file-upload-button {
    @apply text-gray-800 bg-gray-100
      text-sm cursor-pointer
      border-0 border-r border-gray-300
      py-2 px-3;
    margin-inline-end: 0.5rem;
  }
  .input {
    @apply w-full py-2 px-3 mb-4 text-sm appearance-none border  h-9 border-gray-300 
        focus:outline-none focus:border-gray-400 focus:shadow-md transition duration-100 ease-out;
  }
  .input-text {
    @apply py-2 px-3 mb-4 text-sm appearance-none border border-r-0 h-9 border-gray-300 bg-gray-100 whitespace-nowrap;
  }
  .input-sm {
    @apply w-full py-2 px-3 mb-2 text-xxs appearance-none border border-gray-300 h-8
        focus:outline-none focus:border-gray-400 focus:shadow-md transition duration-100 ease-out;
  }
  .input-text-sm {
    @apply py-2 px-3 mb-2 h-8 text-xxs appearance-none border border-r-0 border-gray-300 bg-gray-100 whitespace-nowrap;
  }
  .invalid-tooltip {
    @apply absolute z-10 p-1 px-2 text-white text-sm bg-red-500;
  }
  .button {
    @apply py-2 px-4 text-sm font-medium cursor-pointer hover:shadow-xl hover:bg-opacity-90 hover:scale-95
        transition transform duration-100  ease-out;
  }
  /* .button-sec {
    @apply px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent hover:bg-blue-200  hover:scale-95 focus:outline-none;
  }
  .button-gray {
    @apply px-4 py-2 text-sm font-medium text-gray-900 bg-gray-200 border border-transparent hover:scale-95 hover:bg-gray-300 focus:outline-none;
  }
  .button-yellow {
    @apply px-4 py-2 text-sm font-medium text-yellow-700 bg-yellow-200 border border-transparent hover:scale-95 hover:bg-yellow-300 focus:outline-none;
  }
  .button-red {
    @apply px-4 py-2 text-sm font-medium text-red-900 bg-red-200 border border-transparent hover:scale-95 hover:bg-red-300 focus:outline-none;
  } */
  .card {
    @apply shadow-sm border-gray-200 border bg-white h-48 cursor-pointer hover:shadow-lg transition duration-300 ease-out;
  }
  .side-item {
    @apply cursor-pointer text-white border-slate-700 border-b bg-slate-900 hover:bg-slate-700 transition duration-300 ease-out;
  }
  .checkbox-container {
    @apply block relative pl-8 cursor-pointer;
  }
  .map-icons {
    @apply w-[29px] h-[29px] my-auto border border-gray-300 p-1 border-r-0 text-[#5b5b5b];
  }

  .attr-icons {
    @apply w-[28px] h-[28px] my-auto border border-gray-300 p-1 border-r-0 text-[#5b5b5b];
  }
  .checkbox {
    @apply absolute left-1 h-4 w-4 bg-gray-300 hover:bg-gray-400 after:ml-1.5 after:m-cb after:h-2 after:w-1 
    after:border-r after:border-b after:border-white after:transform after:rotate-45 after:absolute after:hidden;
  }

  .radio-container {
    @apply block relative pl-8 cursor-pointer;
  }

  .radio {
    @apply absolute left-1 h-4 w-4 rounded-full bg-gray-300 hover:bg-gray-400 after:ml-1.5 after:mt-1.5 after:m-cb after:h-1 after:w-1 
    after:border-r after:border-b after:rounded-full after:border-white after:bg-white after:absolute after:hidden;
  }

  .boxes {
    @apply p-4 bg-white my-4;
  }

  .table-head {
    @apply  bg-gray-300 py-2 border-white font-normal border sticky  top-0 z-10 w-fit;
  }

  .table-row {
    @apply odd:bg-gray-100 even:bg-gray-200;
  }
  .table-data {
    @apply py-2 border border-white text-ssm;
  }

  /* Landing */
  .form-heading {
    @apply border-b-2 py-2 text-base mx-2 cursor-pointer px-2;
  }
  .cont-heading {
    @apply text-2xl relative max-w-96 mx-auto text-center;
  }
  /* Overview */
  .time-btn {
    @apply border px-3 py-2 text-sm h-8 cursor-pointer bg-white text-yellow-900 hover:bg-yellow-300 hover:text-yellow-900  
    hover:shadow-xl hover:bg-opacity-90
    transition transform duration-100  ease-out checked:bg-yellow-300 checked:text-yellow-900;
  }
  /* Map */
  .toolbar-icons {
    @apply text-3xl border p-1.5 cursor-pointer border-r-0 hover:bg-gray-200 active:bg-gray-200;
  }

  .form-select {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23a0aec0'%3e%3cpath d='M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z'/%3e%3c/svg%3e");
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: right 0.5rem center;
    background-size: 1.5em 1.5em;
  }
}

.leaflet-rotate-icon {
  background-image: url(https://geodit.in/static/geodit/images/leaflet/rotate.png) !important;
}

.leaflet-merge-icon {
  background-image: url(https://geodit.in/static/geodit/images/leaflet/merge.png) !important;
}

.leaflet-detach-icon {
  background-image: url(https://geodit.in/static/geodit/images/leaflet/detach.png) !important;
}

.leaflet-delete-icon {
  background-image: url(https://geodit.in/static/geodit/images/leaflet/delete.png) !important;
}

.leaflet-restore-icon {
  background-image: url(https://geodit.in/static/geodit/images/leaflet/restore.png) !important;
}

.DateRangePickerInput {
  border: 0 !important;
}
.DateRangePicker {
  z-index: 100 !important;
}
.SingleDatePickerInput {
  border: 0 !important;
}
.SingleDatePickerInput > .DateInput {
  width: 120px !important;
}

.DateRangePickerInput > .DateInput {
  width: 90px !important;
}
.DateInput_input {
  padding: 0 !important;
  font-size: 0.85rem !important;
  line-height: 18px !important;
  text-align: center;
}
.DateRangePickerInput_arrow_svg {
  height: 16px !important;
  width: 16px !important;
}

input[type="color" i] {
  width: 35px;
  height: 23px;
}
input[type="color" i]:focus {
  outline: none !important;
}
.hr-lines:before{
  content:" ";
  display: block;
  height: 2px;
  width: 100px;
  position: absolute;
  top: 50%;
  left: 0;
  background: #eab308;
}

.hr-lines:after{
  content:" ";
  display: block;
  height: 2px;
  width: 100px;
  position: absolute;
  top: 50%;
  right: 0;
  background: #eab308;
}

/* .ol-control button { 
  background-color: rgba(40, 40, 40, 0.8) !important;
}
.ol-control button:hover { 
  background-color: rgba(40, 40, 40, 1) !important;
} */
